import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"
import Image from "../components/image"
import ICalendarLink from "react-icalendar-link";
import homevideo from "../assets/video/home.mp4"
import gclLogo from "../assets/images/gcl-logo.svg"
import eventopolisLogo from "../assets/images/eventopolis-logo.png"
import wuerthLogo from "../assets/images/wurth-logo.svg"
import wuerthImg from "../assets/images/wurth-img.jpg"
import wuerthMap from "../assets/images/wurth-map.jpg"
import wuerthMgmt1 from "../assets/images/wurth-mgmt-1.jpg"
import wuerthMgmt2 from "../assets/images/wurth-mgmt-2.jpg"
import wuerthGlobal from "../assets/images/wurth-global.jpg"
import icoPlayer from "../assets/images/icons/player.svg"
import icoHole from "../assets/images/icons/hole.svg"
import icoTrophy from "../assets/images/icons/trophy.svg"
import icoTicket from "../assets/images/icons/ticket.svg"
import icoPrize from "../assets/images/icons/prize.svg"
import boxGallery from "../assets/images/box-gallery.jpg"
import boxHoles from "../assets/images/box-holes.jpg"
import boxPrizes from "../assets/images/box-prizes.jpg"
import boxSponsor from "../assets/images/box-sponsor.jpg"
import SEO from "../components/seo"
import {Row, Col, Card, Divider, Button, Tooltip, Alert} from "antd";
import {CalendarOutlined, LinkOutlined} from "@ant-design/icons";
import {utils} from "../services/utils";

const {Meta} = Card;

const IndexPage = () => {
    const event = {
        title: "Würth FS Open 2021",
        description: "Golf Club Lugano",
        startTime: "2021-08-29T07:30:00+02:00",
        endTime: "2021-08-29T21:30:00+02:00",
        location: "Via Boett 2, 6893 Magliaso, Switzerland"
    }
    const intl = useIntl()
    return (
        <Layout absolute>
            <SEO
                lang={intl.locale}
                title={intl.formatMessage({id: "title"})}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-home">
                <section className="d-flex align-items-center justify-content-center video-container">
                    <div className="container video-content text-center dark">
                        <Row>
                            <Col xs={24}>
                                <h6 className="text-uppercase mb-0 font-secondary">Würth Financial Services
                                    presenta</h6>
                                <h1 className="font-secondary text-uppercase fw-bold">Würth FS Open 2021</h1>
                                <h4 className="font-secondary text-uppercase mb-0 fw-bold">29 agosto 2021</h4>
                                <h4 className="font-secondary text-uppercase">Golf Club Lugano</h4>
                                <img src={gclLogo} alt="Golf Club Lugano - Logo" className="gcl-logo"/>
                            </Col>
                        </Row>
                    </div>
                    <div className="video-overlay"/>
                    <video className="bg-video" autoPlay loop muted playsInline>
                        <source src={homevideo} type="video/mp4"/>
                    </video>
                </section>
                <section>
                    <div className="container">
                        <div className="promo">
                            <Card>
                                <div className="text-center">
                                    <h4 className="text-primary text-uppercase fw-bold py-lg-4">
                                        Una gara unica in Svizzera !
                                    </h4>
                                </div>
                                <Row className="text-center px-1 py-4" gutter={16}>
                                    <Col xs={{span: 12, order: 3}} lg={{span: 4, order: 1}}>
                                        <img src={icoPlayer} className="promo-icon" alt="Wuerth FS Open"/>
                                        <h2 className="promo-title">150</h2>
                                        <h6 className="promo-subtitle">giocatori</h6>
                                        <p className="promo-text">per una gara entusiasmante e coinvolgente</p>
                                    </Col>
                                    <Col xs={0} lg={{span: 1, order: 2}}>
                                        <Divider type="vertical" style={{height: "100%"}}/>
                                    </Col>
                                    <Col xs={{span: 12, order: 4}} lg={{span: 4, order: 3}}>
                                        <img src={icoHole} className="promo-icon" alt="Wuerth FS Open"/>
                                        <h2 className="promo-title">18</h2>
                                        <h6 className="promo-subtitle">buche</h6>
                                        <p className="promo-text">con 18 sponsor e 18 gadget per un valore di CHF
                                            10’000</p>
                                    </Col>
                                    <Col xs={0} lg={{span: 1, order: 4}}>
                                        <Divider type="vertical" style={{height: "100%"}}/>
                                    </Col>
                                    <Col xs={{span: 24, order: 2}} lg={0}>
                                        <Divider type="horizontal"/>
                                    </Col>
                                    <Col xs={{span: 24, order: 1}} lg={{span: 4, order: 5}}>
                                        <img src={icoTrophy} className="promo-icon" alt="Wuerth FS Open"/>
                                        <h2 className="promo-title">100'000</h2>
                                        <h6 className="promo-subtitle">CHF</h6>
                                        <p className="promo-text">5 super premi da CHF 20’000 l’uno<br/>per Hole in One
                                            nelle 5 buche par 3</p>
                                    </Col>
                                    <Col xs={0} lg={{span: 1, order: 6}}>
                                        <Divider type="vertical" style={{height: "100%"}}/>
                                    </Col>
                                    <Col xs={{span: 24, order: 5}} lg={0}>
                                        <Divider type="horizontal"/>
                                    </Col>
                                    <Col xs={{span: 12, order: 6}} lg={{span: 4, order: 7}}>
                                        <img src={icoTicket} className="promo-icon" alt="Wuerth FS Open"/>
                                        <h2 className="promo-title">2'000</h2>
                                        <h6 className="promo-subtitle">CHF</h6>
                                        <p className="promo-text">10 premi a estrazione
                                            da CHF 200 l’uno per il centro sportivo A-Club di Savosa</p>
                                    </Col>
                                    <Col xs={0} lg={{span: 1, order: 8}}>
                                        <Divider type="vertical" style={{height: "100%"}}/>
                                    </Col>
                                    <Col xs={{span: 12, order: 7}} lg={{span: 4, order: 9}}>
                                        <img src={icoPrize} className="promo-icon" alt="Wuerth FS Open"/>
                                        <h2 className="promo-title">17</h2>
                                        <h6 className="promo-subtitle">coppe</h6>
                                        <p className="promo-text">lordo, netto, 3 categorie, nearest to the pin e drive
                                            contest</p>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <div className="mt-5 text-center">
                            <h6 className="text-uppercase mb-4">Chi siamo</h6>
                            <img src={wuerthLogo} alt="Wuerth FS - Logo" className="wuerth-logo mb-4"/>
                            <Row>
                                <Col xs={24} lg={{span: 12, offset: 6}}>
                                    <h4>Würth Financial Services AG è annoverata tra i più esperti broker assicurativi,
                                        leader
                                        sul mercato svizzero.</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} className="text-center my-4">
                                    <a href="https://www.wuerth-fs.com/it/financialservices/startseite/index.php" target="_blank">
                                        <Button type="primary" size="large">
                                            Scopri di più su Würth FS
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                            <Row gutter={{xs: 16, lg: 48}} type="flex" align="start">
                                <Divider/>
                                <Col xs={24} lg={{span: 12, offset: 6}}>
                                    <h4>Direzione aziendale</h4>
                                    <p>Esperienza e continuità: oltre 80 anni di esperienza nel campo del brokerage e Membro fondatore
                                        dell‘associazione svizzera dei broker assicurativi (SIBA)</p>
                                </Col>
                                <Col xs={24} className="mb-5">
                                    <img src={wuerthMgmt1} alt="Wuerth" className="wuerth-img mb-3 d-inline-block"/>
                                    <img src={wuerthMgmt2} alt="Wuerth" className="wuerth-img mb-3 d-inline-block"/>
                                </Col>
                                <Col xs={24} lg={8} className="mb-3">
                                    <img src={wuerthImg} alt="Wuerth" className="wuerth-img mb-3 rounded shadow"/>
                                    <h4>Competenze e qualifiche tecniche</h4>
                                    <p>circa 60 addetti qualificati</p>
                                </Col>
                                <Col xs={24} lg={8} className="mb-3">
                                    <img src={wuerthMap} alt="Wuerth" className="wuerth-img mb-3"/>
                                    <h4>Presenza locale e vicinanza al cliente</h4>
                                    <p>4 sedi in tutta la Svizzera</p>
                                </Col>
                                <Col xs={24} lg={8} className="mb-3">
                                    <img src={wuerthGlobal} alt="Wuerth" className="wuerth-img mb-3 rounded shadow"/>
                                    <h4>Presente in tutto il mondo</h4>
                                    <p>Parte di una rete globale di broker assicurativi</p>
                                </Col>
                            </Row>
                        </div>
                        <Divider/>
                        <div className="mt-5 text-center">
                            <h6 className="text-uppercase">Sponsor ufficiali</h6>
                            <h5 className="text-uppercase text-primary fw-bold">Fai click su un logo per scoprirne di più!</h5>
                            <Row type="flex" align="middle">
                                {utils.sponsors.map(sponsor => {
                                    return (
                                        <Col xs={8} lg={4} className="px-lg-3">
                                            <Link to={sponsor.url}>
                                                <Tooltip title="Fai click per scoprire di più">
                                                    <img src={sponsor.logo} alt={sponsor.name}
                                                         className="sp-logo rounded"/>
                                                </Tooltip>
                                            </Link>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                        <div className="mt-5 text-center">
                            <h6 className="text-uppercase mb-4">in collaborazione con</h6>
                            <a href="http://eventopolis.ch/" target="_blank">
                                <img src={eventopolisLogo} alt="Eventopolis" className="eventopolis-logo"/>
                            </a>
                        </div>
                    </div>
                </section>
                <section className="bg-secondary border-top border-bottom">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 32}} type="flex">
                            <Col xs={24} lg={6} className="mb-4">
                                <Link to="/gallery">
                                    <Card
                                        bordered
                                        hoverable
                                        cover={<img alt="Wuerth Open" src={boxGallery}/>}>
                                        <h3>Galleria</h3>
                                        <p>
                                            Tutte le foto dell'evento di domenica 29 agosto 2021
                                        </p>
                                        <div className="text-center">
                                            <Button size="large" type="primary" block>
                                                Vai alla galleria
                                            </Button>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col xs={24} lg={6} className="mb-4">
                                <Link to="/prizes">
                                    <Card
                                        bordered
                                        hoverable
                                        cover={<img alt="Wuerth Open" src={boxPrizes}/>}>
                                        <h3>I premi</h3>
                                        <p>
                                            Un montepremi totale di CHF 102'000 e 17 coppe!
                                        </p>
                                        <div className="text-center">
                                            <Button size="large" type="primary" block>
                                                Scopri i premi
                                            </Button>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col xs={24} lg={6} className="mb-4">
                                <Link to="/holes">
                                    <Card
                                        bordered
                                        hoverable
                                        cover={<img alt="Wuerth Open" src={boxHoles}/>}>
                                        <h3>Le buche</h3>
                                        <p>
                                            A ogni buca i giocatori ricevono un gadget in
                                            regalo da ogni sponsor.
                                        </p>
                                        <div className="text-center">
                                            <Button size="large" type="primary" block>
                                                Scopri le buche
                                            </Button>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col xs={24} lg={6} className="mb-4">
                                <Link to="/sponsor">
                                    <Card
                                        bordered
                                        hoverable
                                        cover={<img alt="Wuerth Open" src={boxSponsor}/>}>
                                        <h3>Gli sponsor</h3>
                                        <p>
                                            18 sponsor per ogni buca, di cui 5 speciali per le buche hole in one.
                                        </p>
                                        <div className="text-center">
                                            <Button size="large" type="primary" block>
                                                Scopri gli sponsor
                                            </Button>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="text-center container">
                        <h5 className="text-primary text-uppercase fw-bold mb-5">
                            Informazioni utili
                        </h5>
                        <Row>
                            <Col xs={24} lg={8} className="mb-4">
                                <h5 className="text-uppercase fw-bold">Quando</h5>
                                <p>Domenica<br/>29 agosto 2021</p>
                                <ICalendarLink event={event}>
                                    <Button type="primary" ghost icon={<CalendarOutlined/>}>
                                        Aggiungi al calendario
                                    </Button>
                                </ICalendarLink>
                            </Col>
                            <Divider className="d-lg-none"/>
                            <Col xs={24} lg={8} className="mb-4">
                                <h5 className="text-uppercase fw-bold">Dove</h5>
                                <p>Golf Club Lugano<br/>Via Boett 2, CH 6893 Magliaso<br/>
                                    <a href="https://www.golflugano.ch/" target="_blank">
                                        www.golflugano.ch
                                    </a></p>
                                <a href="https://goo.gl/maps/jx3ojByTkqeks68t7" target="_blank">
                                    <Button type="primary" ghost icon={<LinkOutlined/>}>
                                        Visualizza mappa
                                    </Button>
                                </a>
                            </Col>
                            <Divider className="d-lg-none"/>
                            <Col xs={24} lg={8} className="mb-4">
                                <h5 className="text-uppercase fw-bold">Orario</h5>
                                <p>Inizio gara ore 07.30, fine gara ore 18.00.<br/>Premiazione ore 19.00 con ricco
                                    aperitivo!</p>
                                <Alert type="warning"
                                       message="In caso di maltempo, l’evento sarà rimandato a domenica 19 settembre 2021."/>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default IndexPage
